import { useSelector } from 'react-redux'
import { listData, userData } from 'state/leaderBoard/selectors'

export const useGetSelectListLeaderBoard = () => {
  return useSelector(listData)
}

export const useGetUserInfo = () => {
  return useSelector(userData)
}
