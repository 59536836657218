import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 9">
      <path d="M11.925 0L6.75 5.59459L1.575 0L0 1.7027L6.75 9L13.5 1.7027L11.925 0Z" />
    </Svg>
  )
}

export default Icon
