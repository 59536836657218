import { Price } from '@sonefi-dex/sdk'
import { Text, AutoRenewIcon, Flex } from 'packages/uikit'
import { StyledBalanceMaxMini } from './styleds'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted, ...props }: TradePriceProps) {
  const formattedPrice = showInverted ? price?.toSignificant(6) : price?.invert()?.toSignificant(6)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `1 ${price?.quoteCurrency?.symbol} ≈ ${price?.baseCurrency?.symbol}`
    : `1 ${price?.baseCurrency?.symbol} ≈ ${price?.quoteCurrency?.symbol}`

  return (
    <Text color="var(--white)" {...props} style={{ alignItems: 'center', display: 'flex', width: '100%' }}>
      {show ? (
        <Flex alignItems="center">
          <StyledBalanceMaxMini
            onClick={(e) => {
              e.stopPropagation()
              setShowInverted(!showInverted)
            }}
          >
            <AutoRenewIcon width="18px" />
          </StyledBalanceMaxMini>
          {label} {formattedPrice ?? '-'}
        </Flex>
      ) : (
        '-'
      )}
    </Text>
  )
}
