export function parseString(input) {
  // Regular expressions to capture numbers and units (ETH, SONE)
  const regex = /(\d+\.?\d*)\s*(ETH|SONE)/g
  const matches = [...input.matchAll(regex)]

  // Initialize an object to store the result
  const result = {
    action: '',
    fromAmount: null,
    fromUnit: '',
    toAmount: null,
    toUnit: '',
  }

  // Identify the action (Add or Swapping)
  if (input.includes('Add')) {
    result.action = 'Add'
  } else if (input.includes('Swapping')) {
    result.action = 'Swapping'
  } else if (input.includes('Removing')) {
    result.action = 'Removing'
  }

  // If there are two matches, extract them
  if (matches.length === 2) {
    result.fromAmount = parseFloat(matches[0][1])
    result.fromUnit = matches[0][2]
    result.toAmount = parseFloat(matches[1][1])
    result.toUnit = matches[1][2]
  }
  // If there's only one match (as in Swapping case)
  else if (matches.length === 1) {
    result.fromAmount = parseFloat(matches[0][1])
    result.fromUnit = matches[0][2]

    // Look for "for" to capture the second amount in Swapping
    const forMatch = input.match(/for\s+(\d+\.?\d*)/)
    if (forMatch) {
      result.toAmount = parseFloat(forMatch[1])
      result.toUnit = '' // Adjust if needed
    }
  }

  return result
}
