import styled from 'styled-components'
import {
  ChartIcon,
  Flex,
  Heading,
  HistoryIcon,
  IconButton,
  NotificationDot,
  Text,
  useModal,
  ChartDisableIcon,
} from 'packages/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useExpertModeManager } from 'state/user/hooks'
import RefreshIcon from 'components/Svg/RefreshIcon'

interface Props {
  title: string
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const CurrencyInputContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 10px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 16px 25px 0px 25px;
  }
`

const StyledHeading = styled(Text).attrs({ color: 'var(--white)', fontSize: ['28px'] })`
  font-weight: 500;
`

const StyledText = styled(Text)`
  color: #fff;

  ${({ theme }) => theme.mediaQueries.xs} {
    font-size: 12px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 16px;
  }
`

const StyledFLexMobile = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.xxs} {
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQueries.xs} {
    flex-direction: unset;
  }
`

// const ColoredIconButton = styled(IconButton)`
//   color: ${({ theme }) => theme.colors.textScroll};
// `

const CurrencyInputHeader: React.FC<Props> = ({
  title,
  subtitle,
  setIsChartDisplayed,
  isChartDisplayed,
  hasAmount,
  onRefreshPrice,
}) => {
  const [expertMode] = useExpertModeManager()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)

  return (
    <CurrencyInputContainer>
      <StyledFLexMobile width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-start" width="100%">
          <StyledHeading as="h2">{title}</StyledHeading>
          <StyledText>{subtitle}</StyledText>
        </Flex>
        <Flex>
          {/* {setIsChartDisplayed && (
            <ColoredIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
              {isChartDisplayed ? <ChartDisableIcon color="#ff6107" /> : <ChartIcon width="24px" color="#ff6107" />}
            </ColoredIconButton>
          )} */}
          <NotificationDot show={expertMode}>
            <GlobalSettings color="transparent" mr="0" />
          </NotificationDot>
          {/* <IconButton onClick={onPresentTransactionsModal} variant="text" scale="sm">
            <HistoryIcon color="#ffffff" width="24px" />
          </IconButton>
          <IconButton variant="text" scale="sm" onClick={() => onRefreshPrice()}>
            <RefreshIcon disabled={!hasAmount} width="27px" />
          </IconButton> */}
        </Flex>
      </StyledFLexMobile>
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
